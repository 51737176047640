/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--style4 --left --full" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"uvod"} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2 mt--06 flex--center" style={{"maxWidth":1310}} anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":500}} anim={""} animS={"3"}>
              
              <Image style={{"maxWidth":1080}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/3d47464d4bb945828dd2fc068725bacf_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/38719/3d47464d4bb945828dd2fc068725bacf_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38719/3d47464d4bb945828dd2fc068725bacf_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/38719/3d47464d4bb945828dd2fc068725bacf_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/38719/3d47464d4bb945828dd2fc068725bacf_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/38719/3d47464d4bb945828dd2fc068725bacf_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Připravte se na budoucnost s našimi kurzy!"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Rozvíjejte své dovednosti a zůstaňte ve hře s nejnovějšími trendy. S našimi kurzy zůstanete vždy o krok napřed. Učte se vlastním tempem a aplikujte získané znalosti ve svém pracovním i osobním životě.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pl--40 pr--40 pt--50" style={{"backgroundColor":"var(--color-supplementary)"}} name={"sluzby"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--2 --full flex--top" style={{"maxWidth":1450}} anim={"2"} animS={"3"} columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":380}}>
              
              <Title className="title-box" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(255, 255, 255);\">Využití v pracovním<br>i osobním životě&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Ať už jste začátečník, který se chce více dozvědět o technologiích zítřka, nebo jste zaměstnanec a snažíte se zvýšit si svou kvalifikaci, naše kurzy se přizpůsobí vašim potřebám. Od základů umělé inteligence až po pokročilé techniky strojového učení, naši zkušení lektoři vás provedou každým krokem vaší cesty. Připojte se k nám a staňte se součástí technologické revoluce, která formuje budoucnost našeho světa.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Title className="title-box title-box--left" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(255, 255, 255);\">Využití umělé inteligence ve výuce</span><br>"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"color: var(--white);\">Objevte, jak umělá inteligence dokáže inovovat výuku. Naše kurzy nabízejí učitelům praktické metody, jak úspěšně integrovat AI do vzdělávacího procesu. Ať už se zajímáte o automatickou tvorbu testů, personalizované učební plány, využití AI pro podporu kritického myšlení nebo zkrátka chcete být krok před vašimi studenty, naše školení vás vybaví potřebnými nástroji a znalostmi. Zkvalitněte svou výuku a buďte připraveni na technologické změny ve školství, které nastaví nové standardy ve vzdělávání budoucnosti.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1e2vjp9 --style2 js-anim  --anim5 --center --full --parallax pb--80 pt--80" name={"citat"} fullscreen={true} parallax={true} anim={"5"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38719/51693ae0fecd436c8d0550dc2c027da7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--3 pb--80 pl--40 flex--bottom" style={{"maxWidth":1310}} anim={"7"} animS={"3"} columns={"3"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={""} animS={"3"}>
              
              <Title className="title-box title-box--center fs--48 lh--14" style={{"maxWidth":""}} content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"informace"}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1450}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3" style={{"maxWidth":""}} anim={"4"} animS={"3"}>
              
              <Title className="title-box fs--48" style={{"maxWidth":""}} content={"Jaké služby vám můžeme nabídnout"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"informace-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--top" style={{"maxWidth":1450}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}} anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box" content={"Profesionálně připravené kurzy"}>
              </Subtitle>

              <Text className="text-box" content={"Naše kurzy jsou připraveny tak, aby zaručily nejen aktuální informace, ale i praktickou aplikovatelnost v reálném světě. S důrazem na kvalitu obsahu a pedagogické metody poskytujeme vzdělání, které skutečně obohatí vaše dovednosti a znalosti.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}} anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box" content={"Kurzy vhodné i pro naprosté začátečníky"}>
              </Subtitle>

              <Text className="text-box" content={"V naší nabídce najdete kurzy, které jsou ideální pro ty, kteří se s tématem AI setkávají poprvé. Naše kurzy vás postupně provedou základními koncepty až k praktickým dovednostem.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}} anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box" content={"Individuální a přátelský přístup"}>
              </Subtitle>

              <Text className="text-box" content={"Přistupujeme k našim studentům s porozuměním a trpělivostí, což zajišťuje, že každý z nich dostane potřebnou podporu pro dosažení svých vzdělávacích cílů. Naši lektoři jsou přístupní a vždy připraveni pomoci s jakýmkoli dotazem nebo problémem.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}} anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box" content={"Psychologické, etické a právní aspekty"}>
              </Subtitle>

              <Text className="text-box" content={"Naše kurzy kladou velký důraz na psychologické, etické a právní stránky nových technologií. Pochopení těchto aspektů je klíčové pro zodpovědné využívání a implementaci všech nových technologií, včetně AI.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}} anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box" content={"Jdeme s dobou, pojďte s námi"}>
              </Subtitle>

              <Text className="text-box" content={"V naší nabídce kurzů neustále reflektujeme nejnovější technologické trendy a inovace. Snažíme se být vždy o krok napřed, abychom vám mohli nabídnout nejaktuálnější vzdělání."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}} anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box" content={"Podporujeme váš kariérní růst<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Naše kurzy jsou navrženy tak, aby podpořily váš profesní rozvoj a pomohly vám dosáhnout kariérních ambicí. Využijte naše znalosti k posunu vaší kariéry na vyšší úroveň."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax" style={{"backgroundColor":"var(--color-blend--95)","marginTop":0,"paddingBottom":0}} name={"kontakt"} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" style={{"maxWidth":1310}} anim={"7"} animS={"3"}>
              
              <Title className="title-box" content={"Pojďte s námi ovládnout technologie zítřka ještě dnes<br>"}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Kontaktujte nás"} use={"page"} href={"/kontaktni-formular"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Skolainovace.cz</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"<span style=\"color: var(--white);\">skolainovace.cz, s. r. o.<br>Email: info@skolainovace.cz<br>Telefon: 795 435 923<br>IČ:&nbsp;21803668<br>Malá Štěpánská 1930/7<br>120 00 Praha 2</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"Zadejte text"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"/o_nas\" style=\"color: var(--white);\">O nás</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/nabidka-kurzu\" style=\"color: var(--white);\">Nabídka kurzů</a><a href=\"/blog\" style=\"color: var(--white);\"><br>Blog<br></a><a href=\"https://www.facebook.com/profile.php?id=61559873844823\" target=\"_blank\" style=\"color: var(--white);\">Facebook</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/zasady-zpracovani-osobnich-udaju\" style=\"color: var(--white);\">Zásady zpracování osobních údajů</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}